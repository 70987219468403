import { render, staticRenderFns } from "./CustomerDetailComponent.vue?vue&type=template&id=4aba7a10&scoped=true&"
import script from "./CustomerDetailComponent.vue?vue&type=script&lang=js&"
export * from "./CustomerDetailComponent.vue?vue&type=script&lang=js&"
import style0 from "./CustomerDetailComponent.vue?vue&type=style&index=0&id=4aba7a10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aba7a10",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBreadcrumbs,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
