<template>
  <div class="content-customer-detail global-content-detail-all">

    <div class="global-content-detail" >
      <div id="iHeaderDetail" ref="iHeaderDetail">
        <header-detail-component-global sTextDetail="Detalle de un cliente:" sBackHref="Customer" />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <v-tabs v-model="tab" class="global-tabs tabs-customer-detail" :touchless="true" center-active
        background-color="transparent">
        <v-tab class="tab-customer-detail" v-for="(item, index) in tabsItems" :key="index">
          {{ item.tab }}
        </v-tab>
        <v-tabs-slider class="divider-tabs"></v-tabs-slider>
      </v-tabs>
      <v-tabs-items v-model="tab" class="global-item-tab">
        <!--#region INFORMACION GENERAL -->
        <v-tab-item>
          <customer-general-information-global :bAdminCustomer="bAdminCustomer" :iTab="tab"
            :sIdCustomer="sIdCustomer" />
        </v-tab-item>
        <!--#endregion INFORMACION GENERAL -->

        <!--#region PEDIDOS DE VENTAS -->
        <v-tab-item>
          <sales-orders-component :iTab="tab" :sIdCustomer="sIdCustomer" />
        </v-tab-item>
        <!--#endregion PEDIDOS DE VENTAS -->

        <!--#region MATERIAS PRIMAS -->
        <v-tab-item>
          <raw-materials-detail-customer-component :iTab="tab" :sIdCustomer="sIdCustomer"
            :bAdminCustomer="bAdminCustomer" />
        </v-tab-item>
        <!--#endregion MATERIAS PRIMAS -->
      </v-tabs-items>
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  name: "CustomerDetail",
  data() {
    return {
      sIdCustomer: "",
      tab: 0,
      tabsItems: [
        { tab: "Información general" },
        { tab: "Pedidos de venta" },
        { tab: "Materias primas" }
      ],
      iHeaderDetail: 0,
      screenHeight: 0,
      heightMax: 0,
      itemsBreadcrumbs: [
        {
          text: "Clientes",
          disabled: false,
          to: { name: "Customer" },
        },
        {
          text: "Detalle del cliente",
          disabled: true,
          to: { name: "" },
        },
      ],
    };
  },
  beforeMount() {
    this.setIdCustomer();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.iHeaderDetail !== undefined) {
          this.heightMax = this.$refs.iHeaderDetail.clientHeight;
          this.heightMax = this.heightMax + 123; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.screenHeight = window.innerHeight - 100;
          this.tabsItems[1].tab = "Pedidos de venta";
        } else {
          this.screenHeight = window.innerHeight - 100;
          this.tabsItems[1].tab = "P. de venta";
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setIdCustomer() {
      this.sIdCustomer = this.$route.params.id;
    },
  },
  computed: {

    bShowCustomer() {
      return this.$store.state.bShowCustomer;
    },
    bAdminCustomer() {
      return this.$store.state.bAdminCustomer;
    },
  },
  watch: {
    bAdminCustomer() {
      return this.$store.state.bAdminCustomer;
    },

  },
};
</script>

<style scoped>
.content-identification-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 12px;
}

.text-identification-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.content-btns-accions {
  display: flex;
  padding: 0px 0px 0px 12px;
  margin-top: 25px;
  margin-bottom: 5px;
  width: 100% !important;
}

.content-btn-primary {
  width: 200px;
}

.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>
